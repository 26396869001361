import React from "react";
import "firebase/firestore";
import Firebase from "firebase";
import { firestore } from "./firebaseSetup";
import { useState, useEffect } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
// eslint-disable-next-line
import JSONPretty from "react-json-pretty";
import { useParams, Link } from "react-router-dom";
import { PortfolioTableWidget } from "./widgets/PortfolioTableWidget";
import { portfolioViewColumns } from "./helpers/portfolioViewColumns";
import * as formatters from "./helpers/formatters";
import { StatWidget } from "./widgets/StatWidget";
import EasyEdit from "react-easy-edit";
import { toast } from "react-toastify";
import * as math from "mathjs";
import { Button, Modal } from "react-bootstrap";

function get(object: any, key1: any, key2: any, default_value: any) {
  var result = object[key1];
  if (typeof result === "undefined") {
    return default_value;
  } else {
    if (key2 === null) {
      return result;
    } else {
      var result2 = result[key2];
      return typeof result2 !== "undefined" ? result2 : default_value;
    }
  }
}

const URL = "ws://localhost:5678";

function reconnectingSocket(url: string) {
  let client: any;
  let isConnected = false;
  let reconnectOnClose = true;
  let messageListeners: any = [];
  let stateChangeListeners: any = [];

  function on(fn: any) {
    messageListeners.push(fn);
  }

  function off(fn: any) {
    messageListeners = messageListeners.filter((l: any) => l !== fn);
  }

  function onStateChange(fn: any) {
    stateChangeListeners.push(fn);
    return () => {
      stateChangeListeners = stateChangeListeners.filter((l: any) => l !== fn);
    };
  }

  function start() {
    client = new WebSocket(URL);

    client.onopen = () => {
      isConnected = true;
      stateChangeListeners.forEach((fn: any) => fn(true));
    };

    const close = client.close;

    // Close without reconnecting;
    client.close = () => {
      reconnectOnClose = false;
      close.call(client);
    };

    client.onmessage = (event: any) => {
      // console.log(event);
      messageListeners.forEach((fn: any) => fn(event.data));
    };

    client.onerror = (e: any) => console.error(e);

    client.onclose = () => {
      isConnected = false;
      stateChangeListeners.forEach((fn: any) => fn(false));

      if (!reconnectOnClose) {
        console.log("ws closed by app");
        return;
      }

      console.log("ws closed by server");

      setTimeout(start, 5000);
    };
  }

  start();

  return {
    on,
    off,
    onStateChange,
    close: () => client.close(),
    getClient: () => client,
    isConnected: () => isConnected,
  };
}

const client = reconnectingSocket(URL);

function useMessages(positions: any) {
  const [messages, setMessages] = useState({
    prices: {},
    other: {
      EURUSD: { crncy: 1 },
      PLNUSD: { crncy: 1 },
    },
  });

  useEffect(() => {
    function handleMessage(message: any) {
      //console.log(message);
      var data = JSON.parse(message);
      //console.log(data);
      setMessages({
        prices: data.stocks,
        other: {
          EURUSD: data?.other?.EURUSD?.crncy,
          PLNUSD: data?.other?.PLNUSD?.crncy,
        },
      });
      // console.log(messages);
    }

    client.on(handleMessage);
    return () => client.off(handleMessage);
  }, [messages, setMessages, positions]);

  return messages;
}

type PortfolioParams = {
  id: string;
};
export default function PortfolioView() {
  const [stocks, setStocks] = useState([]);

  //load portfolio values
  let { id } = useParams<PortfolioParams>();
  const portfolioRef = firestore.doc("portfolios/" + id);
  // eslint-disable-next-line
  const [value, loading, error] = useDocumentData(portfolioRef);
  const [tickerList, setTickerList] = useState([]);
  const [positions, setPositions] = useState([]);
  useEffect(() => {
    if (value) {
      setPositions(JSON.parse(value?.tickers));
      setTickerList(JSON.parse(value?.tickers).map((x: any) => x.ticker));
    }
  }, [value]);

  const messages = useMessages(positions);
  // eslint-disable-next-line
  const [message, setMessage] = useState("");
  const [isConnected, setIsConnected] = useState(client.isConnected());

  //do websocket stuff
  useEffect(() => {
    return client.onStateChange(setIsConnected);
  }, [setIsConnected]);

  useEffect(() => {
    if (isConnected) {
      console.log("about to query websocket");
      client.getClient().send(
        JSON.stringify({
          type: "bbg_subscription_update",
          stocks: tickerList,
        })
      );
    }
  }, [isConnected, tickerList]);

  //allow dynamic picking of currency
  function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
  }

  useEffect(() => {
    // console.log("updating with latest price/position ", messages, positions);
    let currencies = {
      USD: 1,
      EUR: messages?.other?.EURUSD,
      PLN: messages?.other?.PLNUSD,
    };

    if (messages.prices !== undefined) {
      setStocks(
        [].concat(
          (positions as any).map((s: any) => {
            return {
              px: get(messages.prices, s.ticker, "px", 0),
              ticker: s.ticker,
              position: s.position,
              nv: get(messages.prices, s.ticker, "px", 0) * s.position,
              yest: get(messages.prices, s.ticker, "yest", 0),
              pct_chg:
                get(messages.prices, s.ticker, "px", 0) /
                  get(messages.prices, s.ticker, "yest", 1) -
                1,
              pl:
                s.position *
                get(messages.prices, s.ticker, "px", 0) *
                (get(messages.prices, s.ticker, "px", 0) /
                  get(messages.prices, s.ticker, "yest", 1) -
                  1) *
                getProperty(
                  currencies,
                  get(messages.prices, s.ticker, "currency", "USD")
                ),
              streak: get(messages.prices, s.ticker, "streak", []),
              seasonality: get(messages.prices, s.ticker, "seasonality", []),
              crncy: get(messages.prices, s.ticker, "currency", "USD"),
              higher: s.higher,
              lower: s.lower,
              to_higher: s.higher / get(messages.prices, s.ticker, "px", 0) - 1,
              to_lower: s.lower / get(messages.prices, s.ticker, "px", 0) - 1,
            };
          })
        )
      );
    }
  }, [messages, positions]);

  const [longs, setLongs] = useState([]);
  const [shorts, setShorts] = useState([]);
  //regenerate longs/shorts once prices have been updated
  useEffect(() => {
    setLongs(
      stocks.filter((s: any) => {
        return s.position > 0;
      })
    );
    setShorts(
      stocks.filter((s: any) => {
        return s.position < 0;
      })
    );
  }, [stocks]);

  const notional_gross = 300000000;

  const long_pl = longs.reduce((a: any, b: any) => a + (b.pl || 0), 0);
  const long_gross = longs.reduce((a: any, b: any) => a + (b.nv || 0), 0);
  const short_pl = shorts.reduce((a: any, b: any) => a + (b.pl || 0), 0);
  const short_gross = Math.abs(
    shorts.reduce((a: any, b: any) => a + (b.nv || 0), 0)
  );
  const total_pl = long_pl + short_pl;
  const total_gross = long_gross + short_gross;

  const bg_color = () => {
    if (total_pl >= 0) {
      return "success";
    } else if (total_pl < -200000) {
      return "danger";
    } else {
      return "dark";
    }
  };

  const handleHeaderUpdate = async (value: any) => {
    // console.log("updating title", value);
    const toUpdate = value && typeof value === "string" ? value : "Book";
    await portfolioRef.update({
      name: toUpdate,
      updatedAt: Firebase.firestore.Timestamp.now(),
    });

    toast.dark(`Book renamed to ${toUpdate}`);
  };

  const handleBookUpdate = async (
    oldValue: any,
    newValue: any,
    row: any,
    column: any
  ) => {
    // console.log("value", value);
    // console.log("old", oldValue);
    // console.log("newvalue", newValue);
    // console.log("row", row);
    // console.log("col", column);

    //[ticker position lower higher] are required for database
    const updated_portfolio = JSON.parse(value?.tickers)
      .filter((e: any) => {
        return (
          e.position &&
          !(e.length === 0) &&
          e.position &&
          typeof e.position === "number" &&
          e.position !== 0
        );
      })
      .map((obj: any) =>
        obj?.ticker === row?.ticker
          ? {
              ticker: row.ticker,
              position: math.evaluate(row.position),
              higher:
                column.dataField === "to_higher"
                  ? math.evaluate(row.to_higher)
                  : row.higher,
              lower:
                column.dataField === "to_lower"
                  ? math.evaluate(row.to_lower)
                  : row.lower,
            }
          : {
              ...obj,
              position: math.evaluate(obj.position),
            }
      );
    await portfolioRef.update({
      tickers: JSON.stringify(updated_portfolio),
      updatedAt: Firebase.firestore.Timestamp.now(),
    });

    toast.dark(
      `Book updated: ${row.ticker} ${column.dataField} to ${newValue} from ${oldValue}`
    );
  };

  //MODAL LOGIC
  const [showAddTickerModal, setShowAddTickerModal] = useState(false);
  const [tickerToAdd, setTickerToAdd] = useState("");
  const [positionToAdd, setPositionToAdd] = useState("");

  const handleTickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTickerToAdd(e.target.value);
  };
  const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPositionToAdd(e.target.value);
  };

  const handleTickerAddSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //add validation
    if (tickerToAdd && positionToAdd) {
      const updated_portfolio = [
        ...JSON.parse(value?.tickers),
        { ticker: tickerToAdd, position: math.evaluate(positionToAdd) },
      ];
      await portfolioRef.update({
        tickers: JSON.stringify(updated_portfolio),
        updatedAt: Firebase.firestore.Timestamp.now(),
      });

      toast.dark(
        `Book updated: added ${tickerToAdd} position size ${positionToAdd}`
      );
      setShowAddTickerModal(false);
      setTickerToAdd("");
      setPositionToAdd("");
    }
  };

  const handleClose = () => {
    setShowAddTickerModal(false);
    setTickerToAdd("");
    setPositionToAdd("");
  };
  const handleShowAddTickerModal = () => setShowAddTickerModal(true);

  return (
    <>
      {/*<JSONPretty data={positions} />*/}
      <div className="row" style={{ marginBottom: "1em" }}>
        <div className="col-6">
          <EasyEdit
            type="text"
            onSave={handleHeaderUpdate}
            value={value?.name}
            placeholder="Enter title"
            onCancel={() => {
              console.log("cancelled");
            }}
            saveButtonLabel="save"
            cancelButtonLabel="cancel"
            attributes={{ name: "input1", id: 1 }}
            hideCancelButton={true}
            hideSaveButton={true}
            cssClassPrefix="header-"
          />
        </div>
        <div className="col-2 offset-4">
          <Button
            onClick={handleShowAddTickerModal}
            style={{ float: "right" }}
            variant="outline-primary"
            className="btn btn-sm btn-icon btn btn-success-transparent btn-color-dark"
          >
            <span className="svg-icon svg-icon-primary svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  xmlns="http://www.w3.org/2000/svg"
                  id="Stockholm-icons-/-Code-/-Plus"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <rect id="bound" x="0" y="0" width="24" height="24" />
                  <circle
                    id="Oval-5"
                    fill="#000000"
                    opacity="0.3"
                    cx="12"
                    cy="12"
                    r="10"
                  />
                  <path
                    d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                    id="Combined-Shape"
                    fill="#000000"
                  />
                </g>
              </svg>
            </span>
          </Button>
          <Link to={"/portfolios/" + id + "/duplicate"}>
            <Button
              style={{ float: "right" }}
              variant="outline-primary"
              className="btn btn-sm btn-icon btn btn-success-transparent btn-color-dark"
            >
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
              </span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <StatWidget
            symbolShape={
              total_pl >= 0 ? "shopping/Chart-line1" : "shopping/Chart-line2"
            }
            baseColor={bg_color()}
            value={formatters.pl_header_formatter(total_pl)}
            label="Today P&L"
            stat={formatters.generic_pct_formatter(total_pl / total_gross)}
          />
        </div>
        <div className="col-4">
          <StatWidget
            symbolShape="devices/cpu1"
            baseColor="primary"
            value={`${formatters.nv_formatter(total_gross)}`}
            label={`Current gross (${formatters.generic_pct_formatter(
              total_gross / notional_gross
            )} of notional)`}
          />
        </div>
        <div className="col-4">
          <StatWidget
            symbolShape="devices/cpu2"
            baseColor="dark"
            value={`${formatters.generic_pct_formatter(
              (long_gross - short_gross) / total_gross
            )} net`}
            label={`${formatters.generic_pct_formatter(
              (long_gross - short_gross) / notional_gross
            )} on notional`}
          />
        </div>
      </div>
      <div className="col-xl-6">
        {positions && (
          <>
            <PortfolioTableWidget
              className="card-stretch gutter-b"
              title="Longs"
              data={longs}
              columns={portfolioViewColumns}
              pl={long_pl}
              gross={formatters.nv_formatter(0)}
              alerts=""
              updateMethod={handleBookUpdate}
            />
          </>
        )}
      </div>
      <div className="col-xl-6">
        {positions && (
          <>
            <PortfolioTableWidget
              className="card-stretch gutter-b"
              title="Shorts"
              data={shorts}
              columns={portfolioViewColumns}
              pl={short_pl}
              gross={formatters.nv_formatter(0)}
              alerts=""
              updateMethod={handleBookUpdate}
            />
          </>
        )}
      </div>
      <div className="websocket-connected-indicator">
        <div className={isConnected ? "" : "spinner spinner-success"}></div>
        <span className="label pulse pulse-success mr-10">
          <span className="position-relative"></span>
          <span
            className={isConnected ? "pulse-ring pulse-success" : ""}
          ></span>
        </span>
      </div>

      <Modal
        show={showAddTickerModal}
        onHide={handleClose}
        autoFocus={false}
        animation={false}
      >
        <Modal.Body>
          <form
            id="kt_modal_new_target_form"
            className="form"
            onSubmit={handleTickerAddSubmit}
          >
            {/*begin::Heading*/}
            <div className="mb-13 text-center">
              {/*begin::Title*/}
              <h1 className="mb-3">Add ticker</h1>
              {/*end::Title*/}
              {/*begin::Description*/}
              <div className="text-muted fw-bold fs-5">
                Add ticker to portfoilo.
              </div>
              {/*end::Description*/}
            </div>
            {/*end::Heading*/}
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-8 fv-row">
              {/*begin::Label*/}
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Ticker</span>
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="tooltip"
                  title="Specify ticker name"
                />
              </label>
              {/*end::Label*/}
              <input
                type="text"
                className="form-control form-control-solid"
                placeholder="Enter ticker"
                name="target_ticker"
                value={tickerToAdd}
                onChange={handleTickerChange}
                autoFocus
              />
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-8 fv-row">
              {/*begin::Label*/}
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Position size</span>
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="tooltip"
                  title="Specify position size"
                />
              </label>
              {/*end::Label*/}
              <input
                type="text"
                className="form-control form-control-solid"
                placeholder="Enter position size"
                name="target_position_size"
                value={positionToAdd}
                onChange={handlePositionChange}
              />
            </div>
            {/*end::Input group*/}
            {/*begin::Actions*/}
            <div className="text-center">
              <Button variant="light" className="me-3" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                className="me-3"
                id="kt_modal_new_target_submit"
                type="submit"
              >
                <span className="indicator-label">Submit</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              </Button>
            </div>
            {/*end::Actions*/}
          </form>
          {/*end:Form*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
