import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const defaultOptions = {
  chart: {
    backgroundColor: null,
    borderWidth: 0,
    type: "area",
    margin: [2, 0, 2, 0],
    width: 50,
    height: 20,
    style: {
      overflow: "visible",
    },

    // small optimalization, saves 1-2 ms each sparkline
    skipClone: true,
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tickPositions: [0],
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: "white",
    borderWidth: 1,
    hideDelay: 0,
    shared: true,
    padding: 8,
    outside: true,
    borderColor: "silver",
    borderRadius: 3,
  },
  plotOptions: {
    series: {
      animation: false,
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2,
          },
        },
      },
      fillOpacity: 0.25,
    },
    column: {
      negativeColor: "#910000",
      borderColor: "silver",
    },
  },

  series: [
    {
      data: [],
    },
  ],
};

interface SparkLineProps {
  options:any;
}
const SparkLine = ({options}:SparkLineProps) => {
    const chartOptions = Highcharts.merge(defaultOptions, options);
    return (
        <>
    <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
    />
</>
    )
}

export default SparkLine;
