import React from "react";
import "firebase/firestore";
import { firestore } from "./firebaseSetup";
import { useState, useEffect } from "react";
// eslint-disable-next-line
import JSONPretty from "react-json-pretty";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Redirect } from "react-router";

type PortfolioParams = {
  id: string;
};
export default function PortfolioDuplicate() {
  const [finishedDelete, setFinishedDelete] = useState(false);
  let { id } = useParams<PortfolioParams>();

  useEffect(() => {
    firestore
      .collection("portfolios")
      .get()
      .then((snap) => {
        if (snap.size > 1) {
          firestore
            .doc("portfolios/" + id)
            .delete()
            .then((doc) => {
              toast.dark("Deleted.");
              setFinishedDelete(true);
            })
            .catch((error) => {
              console.log("error", error);
              toast.error("Error deleting book.");
            });
        } else {
          toast.error("Can't delete last portfolio.");
          setFinishedDelete(true);
        }
      });
  }, [id]);

  return <>{finishedDelete && <Redirect to={"/portfolios/"} />}</>;
}
