import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

import { useEffect, useRef, useState, useContext } from "react";
import { auth } from "./firebaseSetup";
import "react-json-pretty/themes/monikai.css";
import { AuthContext } from "./context/AuthContext";
import { toast } from "react-toastify";

interface CaptchaProps {
  recaptcha: firebase.auth.RecaptchaVerifier;
}

const PhoneNumberVerification: React.FC<CaptchaProps> = ({ recaptcha }) => {
  const user = useContext(AuthContext);

  const [digits, setDigits] = useState<string>("");
  const [confirmationResult, setConfirmationResult] =
    useState<firebase.auth.ConfirmationResult | null>(null);
  const [code, setCode] = useState("");

  const phoneNumber = `${digits}`;

  // Sign in
  const signInWithPhoneNumber = async () => {
    setConfirmationResult(
      await auth.signInWithPhoneNumber(phoneNumber, recaptcha)
    );
  };

  // Verify SMS code
  const verifyCode = async () => {
    if (confirmationResult) {
      const result = await confirmationResult.confirm(code);
      console.log(result.user);
      toast.dark("verified code");
    }
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <div>
      <form
        className="form w-100"
        id="kt_sign_in_form"
        onSubmit={handleFormSubmit}
      >
        <div className="text-center mb-10">
          {user ? (
            <h1 className="text-dark mb-3">
              You are signed in but not authorized.
            </h1>
          ) : (
            <h1 className="text-dark mb-3">Sign In with Phone Number</h1>
          )}
        </div>
        {user && (
          <button
            onClick={() => {
              toast.dark("signed out");
              auth.signOut();
            }}
            className="btn btn-lg btn-primary w-100 mb-5"
          >
            Sign Out
          </button>
        )}
        {!user && (
          <>
            {!confirmationResult && (
              <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">
                  Phone Number
                </label>
                <PhoneInput
                  className="form-control form-control-lg form-control-solid"
                  country="US"
                  autoComplete="off"
                  placeholder=""
                  value={digits}
                  onChange={(e) => {
                    if (e !== undefined) {
                      setDigits(e);
                    }
                  }}
                />
              </div>
            )}
            {confirmationResult && (
              <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">
                  Verify Code
                </label>
                <input
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="form-control form-control-lg form-control-solid"
                  autoFocus
                />
              </div>
            )}

            <div className="text-center">
              {confirmationResult && (
                <button
                  onClick={verifyCode}
                  className="btn btn-lg btn-primary w-100 mb-5"
                >
                  Verify Code
                </button>
              )}

              {!confirmationResult && (
                <>
                  <button
                    type="submit"
                    onClick={signInWithPhoneNumber}
                    id="kt_sign_in_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                  >
                    <span className="indicator-label">Continue</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
};

// eslint-disable-next-line

const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  auth
    .signInWithPopup(provider)
    .then((success) => {
      toast.dark("Signed in");
    })
    .catch((err) => err.message);
};

export const SignIn = () => {
  const [recaptcha, setRecaptcha] =
    useState<firebase.auth.RecaptchaVerifier | null>(null);
  const element = useRef(null);
  const user = useContext(AuthContext);

  useEffect(() => {
    if (!recaptcha) {
      const verifier = new firebase.auth.RecaptchaVerifier(element.current, {
        size: "invisible",
      });

      verifier.verify().then(() => setRecaptcha(verifier));
    }
  });

  return (
    <>
      <div className="sign-in-bg d-flex flex-column flex-root">
        <div className="sign-in-illustration d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <a href="/" className="mb-12">
              <img
                alt="Logo"
                src="/assets/media/logos/logo-2.svg"
                className="h-45px"
              />
            </a>
            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              {recaptcha && <PhoneNumberVerification recaptcha={recaptcha} />}
              <div ref={element}></div>
              {!user && (
                <>
                  <div className="text-center text-muted text-uppercase fw-bolder mb-5">
                    or
                  </div>
                  <button
                    onClick={signInWithGoogle}
                    className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                  >
                    <img
                      alt="Logo"
                      src="/assets/media/svg/brand-logos/google-icon.svg"
                      className="h-20px me-3"
                    />
                    Continue with Google
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="d-flex flex-center flex-column-auto p-10">
            <div className="d-flex align-items-center fw-bold fs-6">
              <p className="text-primary px-2">Copyright 2021</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
