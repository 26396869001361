/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Tab, Badge } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import * as formatters from "../helpers/formatters";
import cellEditFactory from "react-bootstrap-table2-editor";

interface PortfolioTableProps {
  className: string;
  title: string;
  data: any;
  columns: any;
  pl: any;
  gross: any;
  alerts: any;
  updateMethod: any;
}
export const PortfolioTableWidget: React.FC<PortfolioTableProps> = ({
  className,
  title,
  data,
  columns,
  pl,
  gross,
  alerts,
  updateMethod,
}) => {
  //could do something here
  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            {title}
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            {alerts}
          </span>
        </h3>
        <div className="card-toolbar">
          <Tab.Container>
            <Badge
              variant={`${pl < 0 ? "danger" : "success"}`}
              style={{ margin: 1 + "em" }}
            >
              {formatters.generic_pl_formatter(pl)}
            </Badge>
            <Badge variant="primary">{gross}</Badge>
          </Tab.Container>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <BootstrapTable
            keyField="ticker"
            data={data}
            columns={columns}
            bordered={false}
            headerWrapperClasses="thead-light"
            classes="table table-borderless table-sm rounded"
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
              afterSaveCell: updateMethod,
              autoSelectText: false,
            })}
          />
        </div>
      </div>
    </div>
  );
};
