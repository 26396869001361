import * as formatters from "./formatters";
export const portfolioViewColumns = [
  {
    dataField: "ticker",
    text: "Ticker",
    align: "left",
    sort: true,
    footer: "Total",
    editable: false,
  },
  {
    dataField: "px",
    text: "Px",
    align: "right",
    headerAlign: "right",
    formatter: formatters.priceFormatter,
    footer: "",
    editable: false,
  },
  {
    dataField: "position",
    text: "Pos",
    align: "right",
    headerAlign: "right",
    formatter: formatters.position_formatter,
    footer: "",
    editable: true,
  },
  {
    dataField: "nv",
    text: "Nv",
    align: "right",
    headerAlign: "right",
    formatter: formatters.nv_formatter,
    sort: true,
    footer: (columnData: any) =>
      columnData.reduce((acc: any, item: any) => acc + item, 0),
    footerFormatter: formatters.nv_total_formatter,
    footerAlign: "right",
    editable: false,
  },
  {
    dataField: "pct_chg",
    text: "%",
    align: "right",
    headerAlign: "right",
    formatter: formatters.pct_pl_formatter,
    sort: true,
    footer: "",
    editable: false,
  },
  {
    dataField: "pl",
    text: "P/L",
    align: "right",
    headerAlign: "right",
    formatter: formatters.pl_formatter,
    sort: true,
    footer: (columnData: any) =>
      columnData.reduce((acc: any, item: any) => acc + item, 0),
    footerFormatter: formatters.pl_total_formatter,
    footerAlign: "right",
    editable: false,
  },
  {
    dataField: "to_higher",
    text: "upside",
    align: "right",
    headerAlign: "right",
    formatter: formatters.pt_upside_formatter,
    sort: true,
    footer: "",
    editable: true,
  },
  {
    dataField: "to_lower",
    text: "downside",
    align: "right",
    headerAlign: "right",
    formatter: formatters.pt_downside_formatter,
    sort: true,
    footer: "",
    editable: true,
  },
  {
    dataField: "streak",
    text: "5d streak",
    formatter: formatters.streak_formatter_sparkline,
    headerAlign: "right",
    footer: "",
    editable: false,
  },
  {
    dataField: "seasonality",
    text: "seasonal",
    formatter: formatters.seasonality_formatter_sparkline,
    headerAlign: "right",
    footer: "",
    sort: true,
    sortFunc: (
      a: any,
      b: any,
      order: any,
      dataField: any,
      rowA: any,
      rowB: any
    ) => {
      var last_element_a = a[a.length - 1];
      var last_element_b = b[b.length - 1];
      if (order === "asc") return last_element_a - last_element_b;
      else return last_element_b - last_element_a;
    },
    editable: false,
  },
];
