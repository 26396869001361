import React from "react";
import SparkLine from "../widgets/sparkline";

interface stockTableRow {
  ticker: string;
  position: number;
  higher: number | null;
  lower: number | null;
  px?: number | null;
  seasonality?: any;
  streak?: any;
  yest?: number;
  crncy?: string;
}

export function priceFormatter(cell: number, row: stockTableRow) {
  if (cell == null) return cell;
  var options = {
    currency: row.crncy,
    style: "currency",
    maximumFractionDigits: 0,
  };
  var formatter = new Intl.NumberFormat("en-us", options);
  return <span>{formatter.format(cell)} </span>;
}

export function position_formatter(value: any) {
  if (value == null) return value;
  value = parseInt(value);
  if (typeof parseInt(value) !== "number") return 0;
  let newValue = value;
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (Math.abs(newValue) >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  if (Math.abs(value) < 100000) {
    newValue = newValue.toPrecision(2);
  } else {
    newValue = newValue.toPrecision(3);
  }
  newValue += suffixes[suffixNum];
  return newValue;
}

export function nv_formatter(value: any) {
  if (value == null) return value;
  let newValue = value;
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (Math.abs(newValue) >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  if (Math.abs(value) > 10000000 || Math.abs(value) < 1000000) {
    newValue = newValue.toPrecision(3);
  } else {
    if (typeof newValue === "number") {
      newValue = newValue.toPrecision(2);
    }
  }
  newValue += suffixes[suffixNum];
  return newValue;
}

export function pl_header_formatter(value: any) {
  if (value == null) return value;
  if (Math.abs(value) < 1000) {
    return 1 * Math.sign(value) + "K";
  } else if (Math.abs(value) < 100000) {
    let newValue = value / 1000;
    return Math.floor(newValue) + "K";
  } else {
    let newValue = value;
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixNum = 0;
    while (Math.abs(newValue) >= 1000) {
      newValue /= 1000;
      suffixNum++;
    }
    if (Math.abs(value) > 10000000 || Math.abs(value) < 1000000) {
      newValue = newValue.toPrecision(3);
    } else {
      if (typeof newValue === "number") {
        newValue = newValue.toPrecision(3);
      }
    }
    newValue += suffixes[suffixNum];
    return newValue;
  }
}

export function nv_total_formatter(
  columDetails: any,
  columnIndex: any,
  valueObject: any
) {
  if (valueObject == null) return "";
  if (valueObject.text == null) return "";
  let initialValue = valueObject.text;
  if (typeof initialValue === "number") {
    let newValue = valueObject.text;
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixNum = 0;
    while (Math.abs(newValue) >= 1000) {
      newValue /= 1000;
      suffixNum++;
    }
    if (typeof newValue === "number") {
      if (
        Math.abs(initialValue) > 10000000 ||
        Math.abs(initialValue) < 1000000
      ) {
        newValue = newValue.toPrecision(3);
      } else {
        newValue = newValue.toPrecision(2);
      }
      newValue += suffixes[suffixNum];
      return newValue;
    }
  }
}

export function pct_pl_formatter(value: any) {
  var fractionDigits = 2;
  if (Math.abs(value) > 0.99) {
    fractionDigits = 0;
  } else if (value < 0) {
    fractionDigits = 1;
  }
  var numString = Number(value).toLocaleString(undefined, {
    style: "percent",
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
  if (value < 0) {
    return (
      <span className="text-danger font-weight-bold py-4">{numString}</span>
    );
  } else {
    return (
      <span className="text-success font-weight-bold py-4">{numString}</span>
    );
  }
}

/*
for longs, low upside/high downside shoudl be red. low downside/high upside should be green.
for shorts, low upside/high downside shoudl be green, low downside/high upside shoudl be red.
*/
export function pt_upside_formatter(value: any, row: stockTableRow) {
  if (!row.higher || !row.px)
    return <span className="text font-weight-bold py-4">-</span>;
  var upside = row.higher / row.px - 1;
  if (upside === -1) {
    return <span className="text font-weight-bold py-4">-</span>;
  }
  if (!isFinite(value)) {
    return <span className="text font-weight-bold py-4">{row.higher}</span>;
  }
  var numString = Number(upside).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
  });
  if (row.position > 0 && upside < 0.1)
    return (
      <span className="text-danger font-weight-bold py-4">{numString}</span>
    );
  if (row.position > 0 && upside > 0.3)
    return (
      <span className="text-success font-weight-bold py-4">{numString}</span>
    );
  if (row.position < 0 && upside < 0.1)
    return (
      <span className="text-success font-weight-bold py-4">{numString}</span>
    );
  if (row.position < 0 && upside > 0.3)
    return (
      <span className="text-danger font-weight-bold py-4">{numString}</span>
    );
  return <span className="text font-weight-bold py-4">{numString}</span>;
}

/*
for longs, low upside/high downside shoudl be red. low downside/high upside should be green.
for shorts, low upside/high downside shoudl be green, low downside/high upside shoudl be red.
*/
export function pt_downside_formatter(value: any, row: stockTableRow) {
  if (!row.lower || !row.px)
    return <span className="text font-weight-bold py-4">-</span>;
  var downside = Math.abs(row.lower / row.px - 1);
  if (value === -1) {
    return <span className="text font-weight-bold py-4">-</span>;
  }
  if (!isFinite(value)) {
    return <span className="text font-weight-bold py-4">{row.lower}</span>;
  }
  var numString = Number(value).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
  });

  if (row.position > 0 && downside < 0.1)
    return (
      <span className="text-success font-weight-bold py-4">{numString}</span>
    );
  if (row.position > 0 && downside > 0.3)
    return (
      <span className="text-danger font-weight-bold py-4">{numString}</span>
    );
  if (row.position < 0 && downside < 0.1)
    return (
      <span className="text-danger font-weight-bold py-4">{numString}</span>
    );
  if (row.position < 0 && downside > 0.3)
    return (
      <span className="text-success font-weight-bold py-4">{numString}</span>
    );
  return <span className="text font-weight-bold py-4">{numString}</span>;
}

export function generic_pct_formatter(value: any) {
  var numString = Number(value).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
  });
  return numString;
}

export function generic_pl_formatter(value: any) {
  if (value == null) return value;
  let newValue = value;
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (Math.abs(newValue) >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  if (typeof newValue === "number") {
    newValue = newValue.toPrecision(3);
  }
  newValue += suffixes[suffixNum];
  if (value < 0) {
    return newValue;
  } else {
    return newValue;
  }
}

export function pl_formatter(value: any) {
  if (value == null) return value;
  var valueToReturn: any = 0;
  if (Math.abs(value) < 1000) {
    valueToReturn = 1 * Math.sign(value) + "K";
  } else if (Math.abs(value) < 1000000) {
    valueToReturn = Math.floor(value / 1000) + "K";
  } else {
    let newValue = value;
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixNum = 0;
    while (Math.abs(newValue) >= 1000) {
      newValue /= 1000;
      suffixNum++;
    }
    if (typeof newValue === "number") {
      if (Math.abs(value) <= 1000) {
        newValue = newValue / 1000;
        newValue = newValue.toPrecision(2);
        suffixNum++;
      } else if (Math.abs(value) < 10000) {
        newValue = newValue.toPrecision(1);
      } else if (Math.abs(value) < 100000) {
        newValue = newValue.toPrecision(2);
      } else {
        newValue = newValue.toPrecision(3);
      }
    }
    newValue += suffixes[suffixNum];
    valueToReturn = newValue;
  }

  if (value < 0) {
    return (
      <span className="text-danger font-weight-bold py-4">{valueToReturn}</span>
    );
  } else {
    return (
      <span className="text-success font-weight-bold py-4">
        {valueToReturn}
      </span>
    );
  }
}

export function pl_total_formatter(
  columDetails: any,
  columnIndex: any,
  valueObject: any
) {
  if (valueObject == null) return "";
  if (valueObject.text == null) return "";
  let initialValue = valueObject.text;
  if (typeof initialValue === "number") {
    let newValue = valueObject.text;
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixNum = 0;
    while (Math.abs(newValue) >= 1000) {
      newValue /= 1000;
      suffixNum++;
    }
    newValue = newValue.toPrecision(3);
    newValue += suffixes[suffixNum];
    if (initialValue < 0) {
      return (
        <span className="text-danger font-weight-bold py-4">{newValue}</span>
      );
    } else {
      return (
        <span className="text-success font-weight-bold py-4">{newValue}</span>
      );
    }
  }
}
export function streak_formatter_sparkline(cell: any, row: stockTableRow) {
  const data = row.streak;
  const options = {
    series: [
      {
        data,
        pointStart: 1,
        negativeColor: "#FF0000",
        color: "#1BC5BD",
      },
    ],
    tooltip: {
      headerFormat: `<span style="font-size:10px">${row.ticker}:</span><br/>`,
      pointFormat: "{point.y}%",
      valueDecimals: 0,
    },
    chart: {
      type: "column",
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
    },
  };
  return <SparkLine options={options} />;
}

export function seasonality_formatter_sparkline(cell: any, row: stockTableRow) {
  const data = row.seasonality;
  const options: any = {
    series: [
      {
        data,
        pointStart: 1,
        negativeColor: "#FF0000",
        color: "#1BC5BD",
      },
    ],
    tooltip: {
      headerFormat: `<span style="font-size:10px">${row.ticker} t+{point.x}:</span><br/>`,
      pointFormat: "{point.y}%",
      valueDecimals: 0,
    },
    chart: {
      type: "area",
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
    },
  };
  return <SparkLine options={options} />;
}
