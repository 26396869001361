import React from "react";
import "firebase/firestore";
import { firestore } from "./firebaseSetup";
import { useCollection } from "react-firebase-hooks/firestore";
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import EasyEdit from "react-easy-edit";

export default function PortfolioList() {
  const [value, loading, error] = useCollection(
    firestore.collection("portfolios")
  );
  return (
    <div>
      <div className="col-xxl-12">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">Portfolios</span>
              <span className="text-muted mt-1 fw-bold fs-7">
                Complete list
              </span>
            </h3>
            <div className="card-toolbar">
              <ul className="nav">
                <li className="nav-item">
                  <a
                    className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                    data-bs-toggle="tab"
                    href="#kt_table_widget_5_tab_1"
                  >
                    List
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="card-body py-3">
            <div className="tab-content">
              <div className="table-responsive">
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <span>Collection: Loading...</span>}
                <table
                  id="portfoliolist"
                  className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
                >
                  <thead>
                    <tr className="border-0">
                      <th className="p-0 w-50px" />
                      <th className="p-0 min-w-150px" />
                      <th className="p-0 min-w-140px" />
                      <th className="p-0 min-w-110px" />
                      <th className="p-0 min-w-50px" />
                    </tr>
                  </thead>
                  <tbody>
                    {value && (
                      <>
                        {value.docs
                          .sort((a: any, b: any) => {
                            var adata = a?.data();
                            var bdata = b?.data();
                            var o1 = adata.status;
                            var o2 = bdata.status;

                            var p1 = adata.updatedAt;
                            var p2 = bdata.updatedAt;
                            if (o1 < o2) return -1;
                            if (o1 > o2) return 1;
                            if (p1 < p2) return 1;
                            if (p1 > p2) return -1;
                            return 1;
                          })
                          .map((doc) => (
                            <PortfolioListItem
                              key={doc.id}
                              item={doc.data() as any}
                              portfolioID={doc.id}
                            />
                          ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p></p>
    </div>
  );
}

const PortfolioListItem: React.FC<any> = ({ item, portfolioID }: any) => {
  const handleStatusUpdate = async (value: any) => {
    console.log("updating title", value);
    await firestore.doc("portfolios/" + portfolioID).update({
      status: value,
    });

    toast.dark(`Book status to ${value}`);
  };
  return (
    <tr>
      <td>
        <div className="symbol symbol-45px me-2">
          <span className="symbol-label">
            <img
              src="/assets/media/iconly/Bulk/Graph.svg"
              className="h-50 align-self-center"
              alt=""
            />
          </span>
        </div>
      </td>
      <td>
        <Link
          to={"/portfolios/" + portfolioID}
          className="text-dark fw-bolder text-hover-primary mb-1 fs-6"
        >
          {item.name}
        </Link>
        <span className="text-muted fw-bold d-block">
          Updated {item?.updatedAt?.toDate().toDateString()}
        </span>
      </td>
      <td className="text-end text-muted fw-bold">
        Created {item?.createdAt?.toDate().toDateString()}
      </td>
      <td className="text-end">
        <EasyEdit
          type="select"
          options={[
            { label: "Primary Book", value: "Primary Book" },
            { label: "Scenario Book", value: "Scenario Book" },
          ]}
          onSave={handleStatusUpdate}
          placeholder={item.status}
          value={item.status}
          instructions=""
          saveOnBlur
          displayComponent={
            <span
              className={
                "badge " +
                {
                  "Primary Book": "badge-light-success",
                  "Scenario Book": "badge-light-warning",
                }[item.status as string]
              }
            >
              {item.status}
            </span>
          }
          hideCancelButton={true}
          hideSaveButton={true}
          cssClassPrefix="status-"
        />
      </td>
      <td className="text-end">
        <Link
          to={"/portfolios/" + portfolioID + "/delete"}
          className="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
        >
          <Button
            variant="outline-danger"
            className="btn btn-sm btn-icon btn-bg-light btn-color-primary"
          >
            <span className="svg-icon svg-icon-2">
              <span className="svg-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="{1}"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="{0}" y="{0}" width="{24}" height="{24}" />
                    <path
                      d="M8.42034438,20 L21,20 C22.1045695,20 23,19.1045695 23,18 L23,6 C23,4.8954305 22.1045695,4 21,4 L8.42034438,4 C8.15668432,4 7.90369297,4.10412727 7.71642146,4.28972363 L0.653241109,11.2897236 C0.260966303,11.6784895 0.25812177,12.3116481 0.646887666,12.7039229 C0.648995955,12.7060502 0.651113791,12.7081681 0.653241109,12.7102764 L7.71642146,19.7102764 C7.90369297,19.8958727 8.15668432,20 8.42034438,20 Z"
                      fill="#000000"
                      opacity="0.3"
                    />
                    <path
                      d="M12.5857864,12 L11.1715729,10.5857864 C10.7810486,10.1952621 10.7810486,9.56209717 11.1715729,9.17157288 C11.5620972,8.78104858 12.1952621,8.78104858 12.5857864,9.17157288 L14,10.5857864 L15.4142136,9.17157288 C15.8047379,8.78104858 16.4379028,8.78104858 16.8284271,9.17157288 C17.2189514,9.56209717 17.2189514,10.1952621 16.8284271,10.5857864 L15.4142136,12 L16.8284271,13.4142136 C17.2189514,13.8047379 17.2189514,14.4379028 16.8284271,14.8284271 C16.4379028,15.2189514 15.8047379,15.2189514 15.4142136,14.8284271 L14,13.4142136 L12.5857864,14.8284271 C12.1952621,15.2189514 11.5620972,15.2189514 11.1715729,14.8284271 C10.7810486,14.4379028 10.7810486,13.8047379 11.1715729,13.4142136 L12.5857864,12 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
              </span>
            </span>
          </Button>
        </Link>
        <Link
          to={"/portfolios/" + portfolioID + "/duplicate"}
          className="btn btn-sm btn-icon btn-bg-light btn-active-color-dark"
        >
          <Button
            variant="outline-primary"
            className="btn btn-sm btn-icon btn-bg-light btn-color-primary"
          >
            <span className="svg-icon svg-icon-2">
              <span className="svg-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
              </span>
            </span>
          </Button>
        </Link>
        <Link
          to={"/portfolios/" + portfolioID}
          className="btn btn-sm btn-icon btn-bg-light btn-active-color-dark"
        >
          <span className="svg-icon svg-icon-primary svg-icon-2x">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x={17}
                  y={4}
                  width={3}
                  height={13}
                  rx="1.5"
                />
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x={12}
                  y={9}
                  width={3}
                  height={8}
                  rx="1.5"
                />
                <path
                  d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                  fill="#000000"
                  fillRule="nonzero"
                />
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x={7}
                  y={11}
                  width={3}
                  height={6}
                  rx="1.5"
                />
              </g>
            </svg>
          </span>
        </Link>
      </td>
    </tr>
  );
};
