import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "fir-auth-tutorial-fac58.firebaseapp.com",
  projectId: "fir-auth-tutorial-fac58",
  storageBucket: "fir-auth-tutorial-fac58.appspot.com",
  messagingSenderId: "200805063134",
  appId: "1:200805063134:web:6f1703c412935be37be54f",
  measurementId: "G-CMSSZ82XJ9",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

export { auth, firestore };
