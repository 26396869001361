import { useContext } from "react";
import { auth } from "./firebaseSetup";
import { Button } from "react-bootstrap";
// eslint-disable-next-line
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { AuthContext } from "./context/AuthContext";
import PortfolioList from "./PortfolioList";
import PortfolioView from "./PortfolioView";
import PortfolioDuplicate from "./PortfolioDuplicate";
import PortfolioDelete from "./PortfolioDelete";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignOut() {
  return (
    <Button
      onClick={() => {
        auth.signOut();
      }}
    >
      Sign Out
    </Button>
  );
}

function App() {
  const user = useContext(AuthContext);
  console.log("user from app", user);
  return (
    <Router>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            id="kt_aside"
            className="aside aside-dark aside-hoverable"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_mobile_toggle"
          >
            <div className="aside-logo flex-column-auto" id="kt_aside_logo">
              <a href="/">
                <img
                  alt="Logo"
                  src="/assets/media/logos/logo-2.svg"
                  className="h-25px logo"
                />
              </a>
              <div
                id="kt_aside_toggle"
                className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
                data-kt-toggle="true"
                data-kt-toggle-state="active"
                data-kt-toggle-target="body"
                data-kt-toggle-name="aside-minimize"
              >
                <span className="svg-icon svg-icon-1 rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)"
                      />
                      <path
                        d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.5"
                        transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)"
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
            <div className="aside-menu flex-column-fluid">
              <div
                className="hover-scroll-overlay-y my-5 my-lg-5"
                id="kt_aside_menu_wrapper"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                data-kt-scroll-wrappers="#kt_aside_menu"
                data-kt-scroll-offset={0}
              >
                <div
                  className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                  id="#kt_aside_menu"
                  data-kt-menu="true"
                >
                  <div className="menu-item">
                    <div className="menu-content pb-2">
                      <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                        Dashboard
                      </span>
                    </div>
                  </div>
                  <div className="menu-item">
                    <a className="menu-link active" href="/portfolios/">
                      <span className="menu-icon">
                        <span className="svg-icon svg-icon-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <path
                              d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                              fill="#000000"
                            />
                          </svg>
                        </span>
                      </span>
                      <span className="menu-title">Portfolio List</span>
                    </a>
                  </div>
                  <div className="menu-item">
                    {/*<a className="menu-link" href="/drawdown">
                      <span className="menu-icon">
                        <span className="svg-icon svg-icon-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <path
                              d="M6,9 L6,15 C6,16.6568542 7.34314575,18 9,18 L15,18 L15,18.8181818 C15,20.2324881 14.2324881,21 12.8181818,21 L5.18181818,21 C3.76751186,21 3,20.2324881 3,18.8181818 L3,11.1818182 C3,9.76751186 3.76751186,9 5.18181818,9 L6,9 Z M17,16 L17,10 C17,8.34314575 15.6568542,7 14,7 L8,7 L8,6.18181818 C8,4.76751186 8.76751186,4 10.1818182,4 L17.8181818,4 C19.2324881,4 20,4.76751186 20,6.18181818 L20,13.8181818 C20,15.2324881 19.2324881,16 17.8181818,16 L17,16 Z"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                            <path
                              d="M9.27272727,9 L13.7272727,9 C14.5522847,9 15,9.44771525 15,10.2727273 L15,14.7272727 C15,15.5522847 14.5522847,16 13.7272727,16 L9.27272727,16 C8.44771525,16 8,15.5522847 8,14.7272727 L8,10.2727273 C8,9.44771525 8.44771525,9 9.27272727,9 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </svg>
                        </span>
                      </span>
                      <span className="menu-title">Drawdown</span>
  </a>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div
              id="kt_header"
              style={{}}
              className="header align-items-stretch"
            >
              <div className="container-fluid d-flex align-items-stretch justify-content-between">
                <div
                  className="d-flex align-items-center d-lg-none ms-n3 me-1"
                  title="Show aside menu"
                >
                  <div
                    className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                    id="kt_aside_mobile_toggle"
                  >
                    <span className="svg-icon svg-icon-2x mt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x={0} y={0} width={24} height={24} />
                          <rect
                            fill="#000000"
                            x={4}
                            y={5}
                            width={16}
                            height={3}
                            rx="1.5"
                          />
                          <path
                            d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                  <a href="../../demo1/dist/index.html" className="d-lg-none">
                    <img
                      alt="Logo"
                      src="/assets/media/logos/logo-2.svg"
                      className="h-30px"
                    />
                  </a>
                </div>
                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                  <div
                    className="d-flex align-items-stretch"
                    id="kt_header_nav"
                  >
                    <div
                      className="header-menu align-items-stretch"
                      data-kt-drawer="true"
                      data-kt-drawer-name="header-menu"
                      data-kt-drawer-activate="{default: true, lg: false}"
                      data-kt-drawer-overlay="true"
                      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                      data-kt-drawer-direction="end"
                      data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                    >
                      <div
                        className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                        id="#kt_header_menu"
                        data-kt-menu="true"
                      >
                        <div className="menu-item me-lg-1">
                          <a
                            className="menu-link active py-3"
                            href="/portfolios/"
                          >
                            <span className="menu-title">Portfolio list</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-stretch flex-shrink-0">
                    <div className="d-flex align-items-stretch flex-shrink-0">
                      <div
                        className="d-flex align-items-center ms-1 ms-lg-3"
                        id="kt_header_user_menu_toggle"
                      >
                        <div
                          className="cursor-pointer symbol symbol-30px symbol-md-40px"
                          data-kt-menu-trigger="click"
                          data-kt-menu-attach="parent"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-flip="bottom"
                        >
                          <img
                            src="/assets/media/avatars/blank.png"
                            alt="avatar"
                          />
                        </div>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                          data-kt-menu="true"
                        >
                          <div className="menu-item px-3">
                            <div className="menu-content d-flex align-items-center px-3">
                              <div className="symbol symbol-50px me-5">
                                <img
                                  alt="Logo"
                                  src="/assets/media/avatars/blank.png"
                                />
                              </div>
                              <div className="d-flex flex-column">
                                <div className="fw-bolder d-flex align-items-center fs-5">
                                  Max Smith
                                  <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                                    Pro
                                  </span>
                                </div>
                                <a
                                  href="/"
                                  className="fw-bold text-muted text-hover-primary fs-7"
                                >
                                  max@kt.com
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="separator my-2" />
                          <div className="menu-item px-5">
                            <a
                              href="../../demo1/dist/account/overview.html"
                              className="menu-link px-5"
                            >
                              My Profile
                            </a>
                          </div>
                          <div className="menu-item px-5">
                            <a
                              href="../../demo1/dist/pages/projects/list.html"
                              className="menu-link px-5"
                            >
                              <span className="menu-text">My Projects</span>
                              <span className="menu-badge">
                                <span className="badge badge-light-danger badge-circle fw-bolder fs-7">
                                  3
                                </span>
                              </span>
                            </a>
                          </div>
                          <div
                            className="menu-item px-5"
                            data-kt-menu-trigger="hover"
                            data-kt-menu-placement="left-start"
                            data-kt-menu-flip="bottom"
                          >
                            <a href="/" className="menu-link px-5">
                              <span className="menu-title">
                                My Subscription
                              </span>
                              <span className="menu-arrow" />
                            </a>
                            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                              <div className="menu-item px-3">
                                <a
                                  href="../../demo1/dist/account/referrals.html"
                                  className="menu-link px-5"
                                >
                                  Referrals
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="../../demo1/dist/account/billing.html"
                                  className="menu-link px-5"
                                >
                                  Billing
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="../../demo1/dist/account/statements.html"
                                  className="menu-link px-5"
                                >
                                  Payments
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="../../demo1/dist/account/statements.html"
                                  className="menu-link d-flex flex-stack px-5"
                                >
                                  Statements
                                  <i
                                    className="fas fa-exclamation-circle ms-2 fs-7"
                                    data-bs-toggle="tooltip"
                                    title="View your statements"
                                  />
                                </a>
                              </div>
                              <div className="separator my-2" />
                            </div>
                          </div>
                          <div className="menu-item px-5">
                            <a
                              href="../../demo1/dist/account/statements.html"
                              className="menu-link px-5"
                            >
                              My Statements
                            </a>
                          </div>
                          <div className="separator my-2" />
                          <div
                            className="menu-item px-5"
                            data-kt-menu-trigger="hover"
                            data-kt-menu-placement="left-start"
                            data-kt-menu-flip="bottom"
                          >
                            <a href="/" className="menu-link px-5">
                              <span className="menu-title position-relative">
                                Language
                                <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                                  English
                                  <img
                                    className="w-15px h-15px rounded-1 ms-2"
                                    src="/assets/media/flags/united-states.svg"
                                    alt="metronic"
                                  />
                                </span>
                              </span>
                            </a>
                            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                              <div className="menu-item px-3">
                                <a
                                  href="../../demo1/dist/account/settings.html"
                                  className="menu-link d-flex px-5 active"
                                >
                                  <span className="symbol symbol-20px me-4">
                                    <img
                                      className="rounded-1"
                                      src="/assets/media/flags/united-states.svg"
                                      alt="metronic"
                                    />
                                  </span>
                                  English
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="../../demo1/dist/account/settings.html"
                                  className="menu-link d-flex px-5"
                                >
                                  <span className="symbol symbol-20px me-4">
                                    <img
                                      className="rounded-1"
                                      src="/assets/media/flags/spain.svg"
                                      alt="metronic"
                                    />
                                  </span>
                                  Spanish
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="../../demo1/dist/account/settings.html"
                                  className="menu-link d-flex px-5"
                                >
                                  <span className="symbol symbol-20px me-4">
                                    <img
                                      className="rounded-1"
                                      src="/assets/media/flags/germany.svg"
                                      alt="metronic"
                                    />
                                  </span>
                                  German
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="../../demo1/dist/account/settings.html"
                                  className="menu-link d-flex px-5"
                                >
                                  <span className="symbol symbol-20px me-4">
                                    <img
                                      className="rounded-1"
                                      src="/assets/media/flags/japan.svg"
                                      alt="metronic"
                                    />
                                  </span>
                                  Japanese
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a
                                  href="../../demo1/dist/account/settings.html"
                                  className="menu-link d-flex px-5"
                                >
                                  <span className="symbol symbol-20px me-4">
                                    <img
                                      className="rounded-1"
                                      src="/assets/media/flags/france.svg"
                                      alt="metronic"
                                    />
                                  </span>
                                  French
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="menu-item px-5 my-1">
                            <a
                              href="../../demo1/dist/account/settings.html"
                              className="menu-link px-5"
                            >
                              Account Settings
                            </a>
                          </div>
                          <div className="menu-item px-5">
                            <a
                              href="../../demo1/dist/authentication/flows/basic/sign-in.html"
                              className="menu-link px-5"
                            >
                              Sign Out
                            </a>
                          </div>
                          <div className="separator my-2" />
                          <div className="menu-item px-5">
                            <div className="menu-content px-5">
                              <label
                                className="form-check form-switch form-check-custom form-check-solid pulse pulse-success"
                                htmlFor="kt_user_menu_dark_mode_toggle"
                              >
                                <input
                                  className="form-check-input w-30px h-20px"
                                  type="checkbox"
                                  defaultValue={1}
                                  name="skin"
                                  id="kt_user_menu_dark_mode_toggle"
                                  data-kt-url="../../demo1/dist/index.html"
                                />
                                <span className="pulse-ring ms-n1" />
                                <span className="form-check-label text-gray-600 fs-7">
                                  Dark Mode
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center d-lg-none ms-2 me-n3"
                        title="Show header menu"
                      >
                        <div
                          className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                          id="kt_header_menu_mobile_toggle"
                        >
                          <span className="svg-icon svg-icon-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M22 11.5C22 12.3284 21.3284 13 20.5 13H3.5C2.6716 13 2 12.3284 2 11.5C2 10.6716 2.6716 10 3.5 10H20.5C21.3284 10 22 10.6716 22 11.5Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.5"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.5 20C15.3284 20 16 19.3284 16 18.5C16 17.6716 15.3284 17 14.5 17H3.5C2.6716 17 2 17.6716 2 18.5C2 19.3284 2.6716 20 3.5 20H14.5ZM8.5 6C9.3284 6 10 5.32843 10 4.5C10 3.67157 9.3284 3 8.5 3H3.5C2.6716 3 2 3.67157 2 4.5C2 5.32843 2.6716 6 3.5 6H8.5Z"
                                  fill="black"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <div
                  id="kt_content_container"
                  className="container"
                  style={{ marginTop: "3em" }}
                >
                  <div className="row g-5 g-xl-8">
                    <Switch>
                      <Route
                        path="/portfolios/:id/duplicate"
                        children={<PortfolioDuplicate />}
                      />
                      <Route
                        path="/portfolios/:id/delete"
                        children={<PortfolioDelete />}
                      />
                      <Route
                        path="/portfolios/:id"
                        children={<PortfolioView />}
                      />
                      <Route path="/portfolios/">
                        <PortfolioList />
                      </Route>
                      <Route path="/logout">
                        <SignOut />
                      </Route>
                      <Route path="/">
                        <Redirect
                          to={{
                            pathname: "/portfolios/",
                          }}
                        />
                      </Route>
                    </Switch>
                  </div>
                  {/*<div className="row g-5 g-xxl-12">
                    <JSONPretty id="json-pretty" data={user} />;
                        </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Router>
  );
}

export default App;
