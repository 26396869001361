import React from "react";
import "firebase/firestore";
import Firebase from "firebase";
import { firestore } from "./firebaseSetup";
import { useState, useEffect } from "react";
// eslint-disable-next-line
import JSONPretty from "react-json-pretty";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Redirect } from "react-router";

type PortfolioParams = {
  id: string;
};
export default function PortfolioDuplicate() {
  const [newID, setNewID] = useState("");
  //load portfolio values
  let { id } = useParams<PortfolioParams>();

  useEffect(() => {
    firestore
      .doc("portfolios/" + id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(doc.data());
          firestore
            .collection("portfolios")
            .add({
              ...doc.data(),
              createdAt: Firebase.firestore.Timestamp.now(),
              updatedAt: Firebase.firestore.Timestamp.now(),
              name: doc.data()?.name + " duplicate",
            })
            .then((docRef) => {
              console.log("success", docRef);
              setNewID(docRef.id);
              toast.success("duplicated book");
            })
            .catch((error) => {
              console.log("error", error);
              toast.error("Error duplicating book.");
            });
        }
      });
  }, [id]);

  return <>{newID && <Redirect to={`/portfolios/${newID}`} />};</>;
}
