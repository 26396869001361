import "firebase/auth";
import "firebase/firestore";
import "react-phone-number-input/style.css";

import { useEffect, useState, useContext } from "react";
import { auth, firestore } from "./firebaseSetup";
import { Button } from "react-bootstrap";
import "react-json-pretty/themes/monikai.css";
import { AuthContext } from "./context/AuthContext";
import { SignIn } from "./SignIn";
interface AuthProps {
  children: React.ReactNode;
}
export const AuthWrap: React.FC<AuthProps> = ({ children }) => {
  const user = useContext(AuthContext);
  const [isAdmin, setAdmin] = useState<boolean>(false);

  useEffect(() => {
    firestore
      .collection("whitelist")
      .doc(user?.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setAdmin(true);
          console.log("found user after all", user, doc);
        } else {
          setAdmin(false);
          console.log("not logged in or logged in as wrong user", user, doc);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [user]);
  return (
    <>
      {isAdmin ? (
        <>
          <SignOut />
          {children}
        </>
      ) : (
        <div className="App">
          <SignIn />
        </div>
      )}
    </>
  );
};

function SignOut() {
  return (
    <Button
      onClick={() => {
        auth.signOut();
      }}
    >
      Sign Out
    </Button>
  );
}
