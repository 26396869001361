import React from "react";

interface StatWidgetProps {
  className?: string;
  symbolShape: string;
  baseColor: string;
  value: string;
  label: string;
  stat?: string;
}

export const StatWidget = ({
  className,
  symbolShape,
  baseColor,
  value,
  label,
  stat,
}: StatWidgetProps) => {
  return (
    <div
      className={`d-flex align-items-center rounded p-5 mb-7 bg-light-${baseColor} ${className}`}
    >
      {/*begin::Icon*/}
      <span className={`svg-icon svg-icon-${baseColor} svg-icon-2x me-5`}>
        {/*begin::Svg Icon | path: icons/duotone/Home/Library.svg*/}
        {renderSVG(symbolShape)}
        {/*end::Svg Icon*/}
      </span>
      {/*end::Icon*/}
      {/*begin::Title*/}
      <div className="flex-grow-1 me-2">
        <span className="fw-bolder text-gray-800 fs-6">{value}</span>
        <span className="text-muted fw-bold d-block">{label}</span>
      </div>
      {/*end::Title*/}
      {/*begin::Lable*/}
      <span className={`fw-bolder text-${baseColor} py-1`}>{stat}</span>
      {/*end::Lable*/}
    </div>
  );
};

function renderSVG(param: any) {
  switch (param) {
    case "devices/cpu1":
      return cpu1();
    case "devices/cpu2":
      return cpu2();
    case "shopping/Chart-line1":
      return upChart();
    case "shopping/Chart-line2":
      return downChart();
    default:
      return "";
  }
}

const upChart = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <defs></defs>
    <g
      id="Stockholm-icons-/-Shopping-/-Chart-line1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="bound" x="0" y="0" width="24" height="24"></rect>
      <path
        d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
        id="Path-95"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <path
        d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
        id="Path-97"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      ></path>
    </g>
  </svg>
);

const downChart = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <defs></defs>
    <g
      id="Stockholm-icons-/-Shopping-/-Chart-line2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="bound" x="0" y="0" width="24" height="24"></rect>
      <path
        d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
        id="Path-95"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <path
        d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
        id="Path-97"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
        transform="translate(14.000019, 10.749981) scale(1, -1) translate(-14.000019, -10.749981) "
      ></path>
    </g>
  </svg>
);

const cpu1 = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <defs></defs>
    <g
      id="Stockholm-icons-/-Devices-/-CPU1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="bound" x="0" y="0" width="24" height="24"></rect>
      <rect
        id="Rectangle"
        fill="#000000"
        opacity="0.3"
        x="4"
        y="4"
        width="16"
        height="16"
        rx="2"
      ></rect>
      <rect
        id="Rectangle-2"
        fill="#000000"
        opacity="0.3"
        x="9"
        y="9"
        width="6"
        height="6"
      ></rect>
      <path
        d="M20,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,8 C22,8.55228475 21.5522847,9 21,9 L20,9 L20,7 Z"
        id="Rectangle-3"
        fill="#000000"
      ></path>
      <path
        d="M20,11 L21,11 C21.5522847,11 22,11.4477153 22,12 L22,12 C22,12.5522847 21.5522847,13 21,13 L20,13 L20,11 Z"
        id="Rectangle-3-Copy-6"
        fill="#000000"
      ></path>
      <path
        d="M20,15 L21,15 C21.5522847,15 22,15.4477153 22,16 L22,16 C22,16.5522847 21.5522847,17 21,17 L20,17 L20,15 Z"
        id="Rectangle-3-Copy-7"
        fill="#000000"
      ></path>
      <path
        d="M3,7 L4,7 L4,9 L3,9 C2.44771525,9 2,8.55228475 2,8 L2,8 C2,7.44771525 2.44771525,7 3,7 Z"
        id="Rectangle-3-Copy-5"
        fill="#000000"
      ></path>
      <path
        d="M3,11 L4,11 L4,13 L3,13 C2.44771525,13 2,12.5522847 2,12 L2,12 C2,11.4477153 2.44771525,11 3,11 Z"
        id="Rectangle-3-Copy-8"
        fill="#000000"
      ></path>
      <path
        d="M3,15 L4,15 L4,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,16 C2,15.4477153 2.44771525,15 3,15 Z"
        id="Rectangle-3-Copy-9"
        fill="#000000"
      ></path>
    </g>
  </svg>
);

const cpu2 = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <defs></defs>
    <g
      id="Stockholm-icons-/-Devices-/-CPU2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="bound" x="0" y="0" width="24" height="24"></rect>
      <rect
        id="Rectangle"
        fill="#000000"
        opacity="0.3"
        x="3"
        y="3"
        width="18"
        height="18"
        rx="1"
      ></rect>
      <path
        d="M11,11 L11,13 L13,13 L13,11 L11,11 Z M10,9 L14,9 C14.5522847,9 15,9.44771525 15,10 L15,14 C15,14.5522847 14.5522847,15 14,15 L10,15 C9.44771525,15 9,14.5522847 9,14 L9,10 C9,9.44771525 9.44771525,9 10,9 Z"
        id="Rectangle-2"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      ></path>
      <rect
        id="Rectangle-3"
        fill="#000000"
        opacity="0.3"
        x="5"
        y="5"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-8"
        fill="#000000"
        opacity="0.3"
        x="5"
        y="9"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-9"
        fill="#000000"
        opacity="0.3"
        x="5"
        y="13"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy"
        fill="#000000"
        opacity="0.3"
        x="9"
        y="5"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-2"
        fill="#000000"
        opacity="0.3"
        x="13"
        y="5"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-3"
        fill="#000000"
        opacity="0.3"
        x="17"
        y="5"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-10"
        fill="#000000"
        opacity="0.3"
        x="17"
        y="9"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-11"
        fill="#000000"
        opacity="0.3"
        x="17"
        y="13"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-7"
        fill="#000000"
        opacity="0.3"
        x="5"
        y="17"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-6"
        fill="#000000"
        opacity="0.3"
        x="9"
        y="17"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-5"
        fill="#000000"
        opacity="0.3"
        x="13"
        y="17"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
      <rect
        id="Rectangle-3-Copy-4"
        fill="#000000"
        opacity="0.3"
        x="17"
        y="17"
        width="2"
        height="2"
        rx="0.5"
      ></rect>
    </g>
  </svg>
);
